import { DateTime } from "luxon";
import { Table } from "react-bootstrap";

const AmazonViewProjectedSummary = ({ projectedSummary}) => { 
  const todaysDate = DateTime.local().toUTC()
  const startOfThisWeek = todaysDate.startOf('week').minus({days: 1})
  const endOfThisWeek = startOfThisWeek.plus({days: 6})
  
  return(
    <div className="p-3 shadow-sm bg-light rounded">
      <p className="fs-2">Projected Summary</p>
      <p>{startOfThisWeek.toFormat('MM/dd/yyyy')} - {endOfThisWeek.toFormat('MM/dd/yyyy')}</p>
      <Table>
        <thead>
          <tr>
            <th>
              Type
            </th>
            <th>
              Percentage
            </th>
            <th>
              Views
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              1 Day
            </td>
            <td>
              {projectedSummary.oneDayPercent.toFixed(2)}%
            </td>
            <td>
              {projectedSummary.oneDayViews}
            </td>
          </tr>
          <tr>
            <td>
              2 Day
            </td>
            <td>
              {projectedSummary.twoDayPercent.toFixed(2)}%
            </td>
            <td>
              {projectedSummary.twoDayViews}
            </td>
          </tr>
          <tr>
            <td>
              More Than 2 Days
            </td>
            <td>
              {projectedSummary.greaterThanTwoDayPercent.toFixed(2)}%
            </td>
            <td>
              {projectedSummary.greaterThanTwoDayViews}
            </td>
          </tr>
          <tr>
            <td>
              Total
            </td>
            <td></td>
            <td>
              {projectedSummary.totalViews}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default AmazonViewProjectedSummary;