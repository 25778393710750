import React from 'react';
import { DeliveryMetrics } from '../../common/common.types'; // Assume you have this imported from your types
import { Button } from 'react-bootstrap';
import FedExScanEventRowComponent from './fedexScanEventRow';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

type DeliveryMetricsRowProps = {
  row: DeliveryMetrics
  isExpanded: boolean
  onToggleExpand: (orderId: string) => void
}

const AmazonDeliveryMetricsRowComponent: React.FC<DeliveryMetricsRowProps> = ({ row, isExpanded, onToggleExpand }) => {

  return (
    <>
      <tr>
        <td>
          <Button
            variant=''
            onClick={() => onToggleExpand(row.orderId)}
          >
            {isExpanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
          </Button>
        </td>
        <td className={`text-center`}>{row.orderId}</td>
        <td className={`text-center`}>{DateTime.fromISO(row.orderDateTime).toFormat('MM/dd/yyyy')}</td>
        <td className={`text-center`}>{DateTime.fromISO(row.promisedShipDate).toFormat('MM/dd/yyyy')}</td>
        <td className={`text-center`}>{DateTime.fromISO(row.promisedDeliveryDateNoExtension).toFormat('MM/dd/yyyy')}</td>
        <td className={`text-center`}>{DateTime.fromISO(row.promisedDeliveryDateWithExtension).toFormat('MM/dd/yyyy')}</td>
        <td className={`text-center`}>{row.actualShipDate ? DateTime.fromISO(row.actualShipDate).toFormat('MM/dd/yyyy hh:mm a') : 'NA'}</td>
        <td className={`text-center`}>{row.actualDeliveryDate ? DateTime.fromISO(row.actualDeliveryDate).toFormat('MM/dd/yyyy hh:mm a') : 'NA'}</td>
        <td className={`text-center`}>{row.carrierName}</td>
        <td className={`text-center`}>{row.shipMethod === 'null' ? 'NA' : row.shipMethod}</td>
        <td className={`text-center`}>{row.trackingId}</td>
        <td className={`text-center`}>{row.unitCount}</td>
        <td className={`text-center`}>{row.actualOrigin}</td>
        <td className={`text-center`}>{row.givenOrigin}</td>
        <td className={`text-center`}>{row.destinationZip}</td>
        <td className={`text-center`}>{row.deliveredLateNoPromiseExtension ? <FontAwesomeIcon icon={faCircleCheck} /> : ''}</td>
        <td className={`text-center`}>{row.cancelledBySeller ? <FontAwesomeIcon icon={faCircleCheck} /> : ''}</td>
        <td className={`text-center`}>{row.invalidTracking ? <FontAwesomeIcon icon={faCircleCheck} /> : ''}</td>
        <td className={`text-center`}>{row.deliveredLateWithPromiseExtension ? <FontAwesomeIcon icon={faCircleCheck} /> : ''}</td>
        <td className={`text-center`}>{row.shippedLate ? <FontAwesomeIcon icon={faCircleCheck} /> : ''}</td>
        <td className={`text-center`}>{row.orderDayOfWeek}</td>
        <td className={`text-center`}>{row.distributionCenter}</td>
        <td className={`text-center`}>{DateTime.fromISO(row.orderCreatedDateTime).toFormat('MM/dd/yyyy hh:mm a')}</td>
        <td className={`text-center`}>{DateTime.fromISO(row.apiOrderCreatedDateTime).toFormat('MM/dd/yyyy hh:mm a')}</td>
      </tr>
      {isExpanded && (
        <tr>
          <td colSpan={24}>
            <FedExScanEventRowComponent row={row.scanEvents} />
          </td>
        </tr>
      )}
    </>
  );
};

export default AmazonDeliveryMetricsRowComponent;