import { DateTime } from "luxon"
import { DayViewTotals, GraphDataPoint, PrimeSpeedRow } from "./common.types"

type SortOptions<T> = {
  field: keyof T
  order: 'asc' | 'desc'
}

export function extractDateValue(value: any): Date | any {
  if (typeof value === 'string') {
    const datePatterns = [
      {
        regex: /^\d{4}-\d{2}-\d{2}$/,                      // YYYY-MM-DD
        format: (v: string) => v,
      },
      {
        regex: /^\d{2}\/\d{2}\/\d{4}$/,                    // MM/DD/YYYY
        format: (v: string) => v.replace(/\//g, '-'),
      },
      {
        regex: /^\d{2}-\d{2}-\d{4}$/,                      // DD-MM-YYYY
        format: (v: string) => {
          const [day, month, year] = v.split('-')
          return `${year}-${month}-${day}`
        },
      },
      {
        regex: /^\d{4}\/\d{2}\/\d{2}$/,                    // YYYY/MM/DD
        format: (v: string) => v.replace(/\//g, '-'),
      },
      {
        regex: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/,          // YYYY-MM-DD HH:MM
        format: (v: string) => v.replace(' ', 'T'),
      },
      {
        regex: /^\d{2}\/\d{2}\/\d{4} \d{1,2}:\d{2} [APap][Mm]$/, // MM/DD/YYYY HH:MM AM/PM
        format: (v: string) => {
          const [date, time] = v.split(' ')
          const formattedDate = date.replace(/\//g, '-')
          return `${formattedDate} ${time}`
        },
      }
    ]

    for (const pattern of datePatterns) {
      if (pattern.regex.test(value)) {
        return new Date(pattern.format(value))
      }
    }
  }

  return value
}

export function sortByFields<T>(array: T[], options: SortOptions<T>[]): T[] {
  const compare = (a: T, b: T): number => {
    for (const option of options) {
      const { field, order } = option
      const aValue = extractDateValue(a[field])
      const bValue = extractDateValue(b[field])

      if (aValue < bValue) {
        return order === 'asc' ? -1 : 1
      }

      if (aValue > bValue) {
        return order === 'asc' ? 1 : -1
      }
    }

    return 0
  }

  return array.slice().sort(compare)
}
export const flattenObject = (obj, parentKey = '', res = {}) => {
  for (let key in obj) {
    const propName = parentKey ? `${parentKey}.${key}` : key
    
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
      flattenObject(obj[key], propName, res) // Flatten nested objects
    } else if (Array.isArray(obj[key])) {
      if (obj[key].every(item => typeof item === 'string')) {
        // Handle string[] by joining elements into a single string
        res[propName] = obj[key].join(', ')
      } else {
        // Flatten arrays with non-string elements
        obj[key].forEach((item, index) => {
          flattenObject(item, `${propName}[${index}]`, res)
        })
      }
    } else {
      // Assign primitive values
      res[propName] = obj[key]
    }
  }
  return res
}

export const calculatePercentage = (value: number, total: number) => (total > 0 ? (value / total) * 100 : 0)

export const calculateViewPercentages = (rows: PrimeSpeedRow[], startDate: string | null, endDate: string | null) => {
  return rows.map((row) => {
    // Accumulate total views across all types
    let totalViews = 0
    let oneDayViews = 0
    let twoDayViews = 0

    row.viewDetails.forEach((viewDetail) => {
      const date = DateTime.fromISO(viewDetail.date)
      if (
        (!startDate || date >= DateTime.fromISO(startDate)) &&
        (!endDate || date <= DateTime.fromISO(endDate))
      ) {
        totalViews += viewDetail.twoDayViews + viewDetail.greaterThanTwoDayViews
        oneDayViews += viewDetail.oneDayViews
        twoDayViews += viewDetail.twoDayViews
      }
    })

    // Calculate the one-day and two-day percentages
    const oneDayPercent = calculatePercentage(oneDayViews, totalViews)
    const twoDayPercent = calculatePercentage(twoDayViews, totalViews)

    // Return the updated row with the calculated percentages
    return {
      ...row,
      oneDayPercent,
      twoDayPercent,
    }
  })
}

export const calculateSummaries = (data: PrimeSpeedRow[], startDate: string | null, endDate: string | null) => {
  const summaries = {
    uniqueASINs: new Set<string>(),
    uniqueOversizeASINs: new Set<string>(),
    totalTwoDaysOrLessOversize: 0,
    totalGreaterThanTwoDaysOversize: 0,
    totalRevenue: 0,
    totalUnits: 0,
    percentageTwoDaysOrLessOversize: 0,
    revenueTwoDaysOrLess: 0,
    unitsTwoDaysOrLess: 0,
    oversizeOnlyRevenue: 0,
    oversizeOnlyUnits: 0,
  }

  data.forEach((row) => {
    // Unique ASINs
    summaries.uniqueASINs.add(row.asin)

    // Only consider Oversize ASINs
    if (row.size === 'Oversize') {
      summaries.uniqueOversizeASINs.add(row.asin)
      
      // Initialize flags for the current row
      let hasTwoDayViews = false;
      let hasGreaterThanTwoDayViews = false;

      // Check view details once
      row.viewDetails.forEach((viewDetail) => {
        const date = DateTime.fromISO(viewDetail.date);
        if (
          (!startDate || date >= DateTime.fromISO(startDate)) &&
          (!endDate || date <= DateTime.fromISO(endDate))
        ) {
          const { twoDayViews, greaterThanTwoDayViews } = viewDetail;

          // Track whether there are views within 2 days
          if (twoDayViews > 0) {
            hasTwoDayViews = true;
          }

          // Track whether there are views beyond 2 days
          if (greaterThanTwoDayViews > 0) {
            hasGreaterThanTwoDayViews = true;
          }
        }
      });

      // Accumulate totals based on the flags set
      if (hasTwoDayViews) {
        summaries.totalTwoDaysOrLessOversize++;
        summaries.revenueTwoDaysOrLess += row.revenue;
        summaries.unitsTwoDaysOrLess += row.units;
      }

      if (hasGreaterThanTwoDayViews) {
        summaries.totalGreaterThanTwoDaysOversize++;
      }

      // Oversize-specific accumulations
      summaries.oversizeOnlyRevenue += row.revenue;
      summaries.oversizeOnlyUnits += row.units;
    }

    // Totals across all ASINs
    summaries.totalRevenue += row.revenue
    summaries.totalUnits += row.units
  })

  // Calculate the percentage for oversize 2 days or less
  const oversizeUnitsTotal = summaries.totalTwoDaysOrLessOversize + summaries.totalGreaterThanTwoDaysOversize
  summaries.percentageTwoDaysOrLessOversize = calculatePercentage(summaries.totalTwoDaysOrLessOversize, oversizeUnitsTotal)

  return {
    ...summaries,
    uniqueASINs: summaries.uniqueASINs.size,
    uniqueOversizeASINs: summaries.uniqueOversizeASINs.size,
  }
}

export function calculateGraphData(data: PrimeSpeedRow[], startDate: string | null, endDate: string | null): GraphDataPoint[] {
  const dateGroupMap: Record<string, PrimeSpeedRow[]> = {}

  // Convert startDate and endDate to Luxon DateTime for comparison
  const startDateTime = startDate ? DateTime.fromISO(startDate) : null
  const endDateTime = endDate ? DateTime.fromISO(endDate) : null

  // Step 1: Group ASINs by dates, but only for "Oversize" items within the selected date range
  data.forEach(row => {
    if (row.size === 'Oversize') {
      row.viewDetails.forEach(viewDetail => {
        const date = DateTime.fromISO(viewDetail.date)

        // Check if the date falls within the selected date range
        if (
          (!startDateTime || date >= startDateTime) &&
          (!endDateTime || date <= endDateTime)
        ) {
          const dateString = date.toISODate() // Use ISO date string as key
          if (!dateGroupMap[dateString]) {
            dateGroupMap[dateString] = []
          }
          dateGroupMap[dateString].push(row)
        }
      })
    }
  })

  // Accumulators to track total counts across all dates
  let totalViewsAcrossAllDates = 0
  let totalOneDayViewsAcrossAllDates = 0
  let totalTwoDayViewsAcrossAllDates = 0

  // Step 2: Sort dates in chronological order
  const sortedDates = Object.keys(dateGroupMap).sort((a, b) =>
    DateTime.fromISO(a).toMillis() - DateTime.fromISO(b).toMillis()
  )

  // Step 3: Calculate the percentages for each date and format the dates
  const graphData: GraphDataPoint[] = sortedDates.map(isoDate => {
    const formattedDate = DateTime.fromISO(isoDate).toFormat('MM/dd/yyyy') // Format date as needed
    const asinRows = dateGroupMap[isoDate]

    let totalViewsForDate = 0
    let totalOneDayViewsForDate = 0
    let totalTwoDayViewsForDate = 0

    // Accumulate views for each ASIN on the given date
    asinRows.forEach(row => {
      const viewDetailForDate = row.viewDetails.find(viewDetail => viewDetail.date === isoDate)
      if (viewDetailForDate) {
        const { oneDayViews, twoDayViews, greaterThanTwoDayViews } = viewDetailForDate

        const totalViews = twoDayViews + greaterThanTwoDayViews
        totalViewsForDate += totalViews
        totalOneDayViewsForDate += oneDayViews
        totalTwoDayViewsForDate += twoDayViews
      }
    })

    // Update cumulative totals across all dates
    totalViewsAcrossAllDates += totalViewsForDate
    totalOneDayViewsAcrossAllDates += totalOneDayViewsForDate
    totalTwoDayViewsAcrossAllDates += totalTwoDayViewsForDate + totalOneDayViewsForDate

    // Calculate percentages for this date
    const lessThan1DayPercent = totalViewsForDate
      ? (totalOneDayViewsForDate / totalViewsForDate) * 100
      : 0
    const lessThan2DaysPercent = totalViewsForDate
      ? (totalTwoDayViewsForDate / totalViewsForDate) * 100
      : 0

    return {
      date: formattedDate, // Use the formatted date for X-axis
      lessThan1DayPercent,
      lessThan2DaysPercent,
    }
  })

  return graphData
}

export function calculateGraphSummary(data: PrimeSpeedRow[], startDate: string | null, endDate: string | null) {
  let oneDayViews = 0
  let twoDayViews = 0
  let greaterThanTwoDayViews = 0
  let totalViews = 0

  // Convert startDate and endDate to Luxon DateTime for comparison
  const startDateTime = startDate ? DateTime.fromISO(startDate) : null
  const endDateTime = endDate ? DateTime.fromISO(endDate) : null

  // Accumulate views for the filtered date range
  data.forEach((row) => {
    if (row.size === 'Oversize' && Array.isArray(row.viewDetails)) {
      row.viewDetails.forEach((viewDetail) => {
        const date = DateTime.fromISO(viewDetail.date)

        // Only include view details within the selected date range
        if (
          (!startDateTime || date >= startDateTime) &&
          (!endDateTime || date <= endDateTime)
        ) {
          const { oneDayViews: odViews, twoDayViews: tdViews, greaterThanTwoDayViews: gt2dViews } = viewDetail

          // Add views to accumulators
          oneDayViews += odViews
          twoDayViews += tdViews
          greaterThanTwoDayViews += gt2dViews
          totalViews += tdViews + gt2dViews
        }
      })
    }
  })

  // Calculate percentages
  const oneDayPercent = totalViews ? (oneDayViews / totalViews) * 100 : 0
  const twoDayPercent = totalViews ? (twoDayViews / totalViews) * 100 : 0
  const greaterThanTwoDayPercent = totalViews ? (greaterThanTwoDayViews / totalViews) * 100 : 0

  return {
    oneDayPercent,
    twoDayPercent,
    greaterThanTwoDayPercent,
    oneDayViews,
    twoDayViews,
    greaterThanTwoDayViews,
    totalViews
  }
}

export function calculateDraftGraphSummary(
  data: PrimeSpeedRow[], 
  startDate: string | null, 
  endDate: string | null
) {
  let oneDayViews = 0;
  let twoDayViews = 0;
  let greaterThanTwoDayViews = 0;
  let totalViews = 0;

  // Convert startDate and endDate to Luxon DateTime for comparison
  const startDateTime = startDate ? DateTime.fromISO(startDate) : null;
  const endDateTime = endDate ? DateTime.fromISO(endDate) : null;

  // Accumulate views for the filtered date range, considering draftPrime
  data.forEach((row) => {
    if (row.size === 'Oversize' && Array.isArray(row.viewDetails)) {
      row.viewDetails.forEach((viewDetail) => {
        const date = DateTime.fromISO(viewDetail.date);

        // Only include view details within the selected date range
        if (
          (!startDateTime || date >= startDateTime) &&
          (!endDateTime || date <= endDateTime)
        ) {
          const { oneDayViews: odViews, twoDayViews: tdViews, greaterThanTwoDayViews: gt2dViews } = viewDetail;

          // Use draftPrime for calculations instead of original isPrime
          if (row.draftPrime !== undefined ? row.draftPrime : row.isPrime) {
            // Add views to accumulators
            oneDayViews += odViews;
            twoDayViews += tdViews;
            greaterThanTwoDayViews += gt2dViews;
            totalViews += tdViews + gt2dViews;
          }
        }
      });
    }
  });

  // Calculate percentages
  const oneDayPercent = totalViews ? (oneDayViews / totalViews) * 100 : 0;
  const twoDayPercent = totalViews ? (twoDayViews / totalViews) * 100 : 0;
  const greaterThanTwoDayPercent = totalViews ? (greaterThanTwoDayViews / totalViews) * 100 : 0;

  return {
    oneDayPercent,
    twoDayPercent,
    greaterThanTwoDayPercent,
    oneDayViews,
    twoDayViews,
    greaterThanTwoDayViews,
    totalViews,
  };
}

export function getProjectedDataForWeek(data: PrimeSpeedRow[]): PrimeSpeedRow[] {
  const todaysDate = DateTime.local().toUTC();
  const startOfThisWeek = todaysDate.startOf('week').minus({ days: 1 }); // Start from Sunday
  const projectedData: PrimeSpeedRow[] = [];

  // Helper function to find the view details for a specific date
  const findViewDetailsForDate = (row: PrimeSpeedRow, targetDate: DateTime) => {
    return row.viewDetails.find(viewDetail =>
      DateTime.fromISO(viewDetail.date).hasSame(targetDate, 'day')
    )
  }

  // Recursively find data from previous weeks until we find a valid non-special view detail
  function findPreviousNonSpecialViewDetail(row: PrimeSpeedRow, currentDate: DateTime, maxWeeksBack: number): any {
    let viewDetail = findViewDetailsForDate(row, currentDate.minus({ weeks: maxWeeksBack }));

    if (!viewDetail || viewDetail.isSpecialDay) {
      if (maxWeeksBack >= 4) { // Set a limit to how many weeks back you want to search
        return null
      }
      return findPreviousNonSpecialViewDetail(row, currentDate, maxWeeksBack + 1)
    }
    return viewDetail
  }

  // Loop through each day from the selected range or default to this week
  for (let i = 0; i <= 6; i++) {
    const currentDate = startOfThisWeek.plus({ days: i });
    let hasDataForDay = false

    // First pass: Check all rows for actual data for this date
    data.forEach((row) => {
      if (row.size === 'Oversize' && Array.isArray(row.viewDetails)) {
        // Try to find the view detail for the current date first
        let viewDetail = findViewDetailsForDate(row, currentDate);

        // If data for the current date exists, use it directly
        if (viewDetail) {
          projectedData.push({
            ...row, 
            viewDetails: [{
              ...viewDetail,
              date: currentDate.toFormat('yyyy-MM-dd') // Set the current date
            }]
          })
          hasDataForDay = true // Mark that we have data for this date
        }
      }
    })

    // Second pass: If no actual data was found for this date in any row, apply projections
    if (!hasDataForDay) {
      data.forEach((row) => {
        if (row.size === 'Oversize' && row.isPrime && Array.isArray(row.viewDetails)) {
          // Search for data from previous weeks
          const viewDetail = findPreviousNonSpecialViewDetail(row, currentDate, 1);

          // If we found data from previous weeks, use it
          if (viewDetail) {
            projectedData.push({
              ...row, 
              viewDetails: [{
                ...viewDetail,
                date: currentDate.toFormat('yyyy-MM-dd') // Set the projected date
              }]
            })
          }
        }
      })
    }
  }

  return projectedData;
}

export function calculateProjectedSummary(
  data: PrimeSpeedRow[]
) {
  let oneDayViews = 0
  let twoDayViews = 0
  let greaterThanTwoDayViews = 0
  let totalViews = 0

  // Accumulate views for the filtered date range
  data.forEach((row) => {
    if (Array.isArray(row.viewDetails)) {
      row.viewDetails.forEach((viewDetail) => {

        const { oneDayViews: odViews, twoDayViews: tdViews, greaterThanTwoDayViews: gt2dViews } = viewDetail

        oneDayViews += odViews
        twoDayViews += tdViews
        greaterThanTwoDayViews += gt2dViews
        totalViews += tdViews + gt2dViews
      })
    }
  })

  // Calculate percentages
  const oneDayPercent = totalViews ? (oneDayViews / totalViews) * 100 : 0
  const twoDayPercent = totalViews ? (twoDayViews / totalViews) * 100 : 0
  const greaterThanTwoDayPercent = totalViews ? (greaterThanTwoDayViews / totalViews) * 100 : 0

  return {
    oneDayPercent,
    twoDayPercent,
    greaterThanTwoDayPercent,
    oneDayViews,
    twoDayViews,
    greaterThanTwoDayViews,
    totalViews
  }
}

export function calculateProjectedGraphData(data: PrimeSpeedRow[]): GraphDataPoint[] {
  const graphData: GraphDataPoint[] = [];

  // Create a map of the dates and their total views/percentages
  const dateMap: Record<string, { totalOneDayViews: number; totalTwoDayViews: number; totalViews: number }> = {};

  data.forEach(row => {
    row.viewDetails.forEach(viewDetail => {
      const isoDate = DateTime.fromISO(viewDetail.date).toISODate();
      if (!dateMap[isoDate]) {
        dateMap[isoDate] = { totalOneDayViews: 0, totalTwoDayViews: 0, totalViews: 0};
      }

      dateMap[isoDate].totalOneDayViews += viewDetail.oneDayViews;
      dateMap[isoDate].totalTwoDayViews += viewDetail.twoDayViews;
      dateMap[isoDate].totalViews += viewDetail.twoDayViews + viewDetail.greaterThanTwoDayViews;
    });
  });

  // Now calculate the percentages for each date
  Object.keys(dateMap).forEach(date => {
    const totalViews = dateMap[date].totalViews;

    const lessThan1DayPercent = totalViews
      ? (dateMap[date].totalOneDayViews / totalViews) * 100
      : 0;

    const lessThan2DaysPercent = totalViews
      ? (dateMap[date].totalTwoDayViews / totalViews) * 100
      : 0;

    graphData.push({
      date, // Use the date as the x-axis
      lessThan1DayPercent,
      lessThan2DaysPercent,
    });
  });

  return graphData;
}

export function getDayViewTotals(data: PrimeSpeedRow[]): DayViewTotals[] {
  const dayViewTotals: Record<string, DayViewTotals> = {};

  data.forEach(row => {
    row.viewDetails.forEach(viewDetail => {
      const date = DateTime.fromISO(viewDetail.date).toISODate(); // Ensure it's stored as a date string (ISO format)

      if (!dayViewTotals[date]) {
        dayViewTotals[date] = {
          date,
          totalOneDayViews: 0,
          totalTwoDayViews: 0,
          totalGreaterThanTwoDayViews: 0
        };
      }

      // Accumulate views for each date
      dayViewTotals[date].totalOneDayViews += viewDetail.oneDayViews;
      dayViewTotals[date].totalTwoDayViews += viewDetail.twoDayViews;
      dayViewTotals[date].totalGreaterThanTwoDayViews += viewDetail.greaterThanTwoDayViews;
    });
  });

  // Return the values as an array
  return Object.values(dayViewTotals);
}