import React, { useState } from 'react';
import { DeliveryMetrics } from '../../common/common.types';
import { Table } from 'react-bootstrap';
import AmazonDeliveryMetricsRowComponent from './amazonDeliveryMetricsRow';

type TableProps = {
  data: DeliveryMetrics[]
};

const AmazonDeliveryMetricsTableComponent: React.FC<TableProps> = ({ data }) => {

  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  const toggleExpand = (rowId: string) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  }

  return (
    <div className="table-responsive" style={{ maxHeight: '700px', overflowY: 'auto' }}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}></th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Order Id</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Order Date</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Promised Ship Date</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Promised Delivery Date (No Extension)</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Promised Delivery Date (With Extension)</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Actual Ship Date (First Scan)</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Actual Delivery Date</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Carrier Name</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Ship Method</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Tracking Id</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Unit Count</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Actual Zip</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Origin Zip</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Destination Zip</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Delivered late without a promise extension?</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Canceled by seller?</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Invalid tracking?</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Delivered late with a promise extension?</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Shipped late (carrier first scan)?</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Order Day of Week</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>DC</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>API Create Time</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>API Ship Date</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <AmazonDeliveryMetricsRowComponent
              key={row.orderId}
              row={row}
              isExpanded={expandedRow === row.orderId}
              onToggleExpand={toggleExpand}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AmazonDeliveryMetricsTableComponent