import { createContext, useContext, useEffect, useState } from "react";
import useAmazonDeliveryMetrics from "../hooks/useAmazonDeliveryMetricsData";
import { DateTime } from "luxon";
import { useSpinner } from "../../spinner/SpinnerContext";
import useAmazonDeliveryMetricsFilters from "../hooks/useAmazonDeliveryMetricsFilters";
import useAmazonDeliveryMetricsXlsxExport from "../hooks/useAmazonDeliveryMetricsXlsxExport";

// Create the context
const AmazonDeliveryMetricsContext = createContext(null);

// Context Provider
export const AmazonDeliveryMetricsProvider = ({ children }) => {

  const getPreviousWeekRange = () => {
    const now = DateTime.local().toUTC();
    const previousSunday = now.minus({ days: now.weekday % 7 + 7 }).toFormat('yyyy-MM-dd');
    const previousSaturday = now.minus({ days: now.weekday % 7 + 1 }).toFormat('yyyy-MM-dd');
    return { previousSunday, previousSaturday };
  };

  const { toggleSpinner } = useSpinner();
  const [selectedStartDate, setSelectedStartDate] = useState(getPreviousWeekRange().previousSunday);
  const [selectedEndDate, setSelectedEndDate] = useState(getPreviousWeekRange().previousSaturday);

  const {
    isLoadingCurrent,
    isPendingCurrent,
    currentError,
    currentData,
    isFetchingCurrent,
    handleFileChange,
    uploadFile,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    refetchCurrent
  } = useAmazonDeliveryMetrics(selectedStartDate, selectedEndDate);

  const {
    setFilteredData,
    applyFilters,
    clearFilters
  } = useAmazonDeliveryMetricsFilters(currentData, startDate, endDate, refetchCurrent);

  const {
    exportXlsx
  } = useAmazonDeliveryMetricsXlsxExport(currentData)

  const handleApplyFilters = (e) => {
    e.preventDefault();
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
    applyFilters();
  };

  const handleClearFilters = (e) => {
    e.preventDefault();
    setStartDate(getPreviousWeekRange().previousSunday);
    setEndDate(getPreviousWeekRange().previousSaturday);
    setSelectedStartDate(getPreviousWeekRange().previousSunday)
    setSelectedEndDate(getPreviousWeekRange().previousSaturday)
    clearFilters();
  };

  const handleAmazonDeliveryMetricsExport = (e) => {
    e.preventDefault();
    exportXlsx()
  };

  const isDataLoading = isPendingCurrent || isFetchingCurrent || isLoadingCurrent

  useEffect(() => {
    toggleSpinner(isDataLoading);
  }, [isDataLoading]);

  // Provide all the state and handlers as context value
  return (
    <AmazonDeliveryMetricsContext.Provider
      value={{
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        isLoadingCurrent,
        currentError,
        currentData,
        handleFileChange,
        uploadFile,
        handleApplyFilters,
        handleClearFilters,
        handleAmazonDeliveryMetricsExport
      }}
    >
      {children}
    </AmazonDeliveryMetricsContext.Provider>
  );
};

// Custom hook to use the context
export const useAmazonDeliveryMetricsContext = () => {
  const context = useContext(AmazonDeliveryMetricsContext);
  if (!context) {
    throw new Error('useAmazonDeliveryMetricsContext must be used within a AmazonDeliveryMetricsProvider');
  }
  return context;
};