import { useState } from "react"
import { DeliveryMetrics } from "../../common/common.types"

const useAmazonDeliveryMetricsFilters = (data: DeliveryMetrics[] | undefined, startDate: string | null, endDate: string | null, refetch) => {
  const [filteredData, setFilteredData] = useState<DeliveryMetrics[]>([])

  const applyFilters = () => {
    if (!data) return

    
    setFilteredData(data) // Update the state with filtered data

    refetch()
  }

  const clearFilters = () => {
    if (!data) return

    setFilteredData(data)

    refetch()
  }

  return {
    setFilteredData,
    applyFilters,
    clearFilters
  }
}

export default useAmazonDeliveryMetricsFilters