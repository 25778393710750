import { Table as Container, Row, Col, Tabs, Tab} from "react-bootstrap"
import { PageSpeedViewsProvider, usePageSpeedViewsContext } from '../amazonPageSpeedViews/contexts/amazonPageSpeedViewsContext'
import AmazonViewSummary from "./components/amazonPageSpeedViewsSummaryComponent"
import AmazonViewGraph from "./components/amazonPageSpeedViewsGraphComponent"
import AmazonViewGraphSummary from "./components/amazonPageSpeedViewsGraphSummaryComponent"
import AmazonFilterForm from "./components/amazonPageSpeedViewsFilterDataComponent"
import UploadAmazonViews from "./components/amazonPageSpeedViewsUploadDataComponent"
import ASINTableComponent from "./components/amazonASINTableComponent"
import DraftGraphViewGraphSummary from "./components/amazonPageSpeedViewsDraftGraphSummary"
import AmazonViewProjectedSummary from "./components/amazonPageViewsSpeedViewsProjectedWeekComponent"
import ProjectedASINTableComponent from "./components/amazonProjectedASINTable"
import AmazonDayByDayProjected from "./components/amazonProjectedDayByDayViewsComponent"

const AmazonPageSpeedViews = () => {

  const {
    draftData,
    error,
    summaries,
    graphData,
    graphSummary,
    draftGraphSummary,
    ONE_DAY_PERCENTAGE_REQUIREMENT,
    TWO_DAY_PERCENTAGE_REQUIREMENT,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    showPrime,
    showOversize,
    showBolingbrook,
    setShowPrime,
    setShowBolingbrook,
    setOversize,
    handleApplyFilters,
    handleClearFilters,
    handleFileChange,
    handleAmazonViewsExport,
    uploadFile,
    togglePrime,
    projectedSummary,
    projectedGraphData,
    filteredRollingData,
    projectedDayByDayData
  } = usePageSpeedViewsContext()

  if (error) return <div>{error.message}</div>

  return (
      <Container className="p-3 fs-4">
      <Row className="justify-content-md-center fs-1 mb-4">Oversize Amazon Page Speed Views</Row>
      <Tabs
        defaultActiveKey='current-metrics'
        id="justify-tab-example"
        className="mb-3"
        justify
      >
        <Tab eventKey="current-metrics" title="Current Metrics">
          <Row className="mb-4">
            <Col md={3}>
              <AmazonViewSummary summaries={summaries} />
            </Col>
            <Col md={9}>
              <AmazonViewGraph
                graphData={graphData}
                ONE_DAY_PERCENTAGE_REQUIREMENT={ONE_DAY_PERCENTAGE_REQUIREMENT}
                TWO_DAY_PERCENTAGE_REQUIREMENT={TWO_DAY_PERCENTAGE_REQUIREMENT}
              />
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={3}>
                <AmazonViewGraphSummary graphSummary={graphSummary} />
            </Col>
            <Col md={3}>
                <DraftGraphViewGraphSummary draftGraphSummary={draftGraphSummary} />
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={{ span: 6, offset: 3 }}>
              <AmazonFilterForm
                selectedStartDate={selectedStartDate}
                setSelectedStartDate={setSelectedStartDate}
                selectedEndDate={selectedEndDate}
                setSelectedEndDate={setSelectedEndDate}
                showPrime={showPrime}
                setShowPrime={setShowPrime}
                showBolingbrook={showBolingbrook}
                setShowBolingbrook={setShowBolingbrook}
                showOversize={showOversize}
                setOversize={setOversize}
                handleApplyFilters={handleApplyFilters}
                handleClearFilters={handleClearFilters}
                handleAmazonViewsExport={handleAmazonViewsExport}
              />
            </Col>
            <Col md={{ span: 1, offset: 2 }}>
              <UploadAmazonViews 
                handleFileChange={handleFileChange} 
                uploadFile={uploadFile}
              />
            </Col>
          </Row>
          <ASINTableComponent
            data={draftData}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            ONE_DAY_PERCENTAGE_REQUIREMENT={ONE_DAY_PERCENTAGE_REQUIREMENT}
            TWO_DAY_PERCENTAGE_REQUIREMENT={TWO_DAY_PERCENTAGE_REQUIREMENT}
            togglePrime={togglePrime}
          />
        </Tab>
        <Tab eventKey="projected-metrics" title="Projected Metrics">
          <Row className="mb-4">
            <Col md={9}>
              <AmazonViewGraph
                graphData={projectedGraphData}
                ONE_DAY_PERCENTAGE_REQUIREMENT={ONE_DAY_PERCENTAGE_REQUIREMENT}
                TWO_DAY_PERCENTAGE_REQUIREMENT={TWO_DAY_PERCENTAGE_REQUIREMENT}
              />
            </Col>
            <Col md={3}>
              <AmazonViewProjectedSummary projectedSummary={projectedSummary} />
              <AmazonDayByDayProjected projectedDayByDayData={projectedDayByDayData} />
            </Col>
          </Row>
          <ProjectedASINTableComponent
            data={filteredRollingData}
            ONE_DAY_PERCENTAGE_REQUIREMENT={ONE_DAY_PERCENTAGE_REQUIREMENT}
            TWO_DAY_PERCENTAGE_REQUIREMENT={TWO_DAY_PERCENTAGE_REQUIREMENT}
          />
        </Tab>
      </Tabs>
      </Container>
      
  )
}

export default function AmazonPageSpeedViewsWrapper() {
  return (
    <PageSpeedViewsProvider>
      <AmazonPageSpeedViews />
    </PageSpeedViewsProvider>
  );
}