import { Button, Form, Row, Col, Container } from "react-bootstrap"

const AmazonDeliveryMetricsFilterComponent = ({
  selectedStartDate, setSelectedStartDate,
  selectedEndDate, setSelectedEndDate,
  handleApplyFilters, handleClearFilters
}) => (
  <Container>
    <Row className="justify-content-evenly">
      <Col>
        <Form>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="startDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={selectedStartDate || ''}
                  onChange={(e) => setSelectedStartDate(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="endDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={selectedEndDate || ''}
                  onChange={(e) => setSelectedEndDate(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Button className="w-100" variant="outline-primary" onClick={handleApplyFilters}>Apply Filters</Button>
            </Col>
            <Col>
              <Button className="w-100" variant="outline-primary" onClick={handleClearFilters}>Clear Filters</Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  </Container>
)

export default AmazonDeliveryMetricsFilterComponent;