import { useEffect, useState } from "react"
import { calculateProjectedSummary, calculateProjectedGraphData, getProjectedDataForWeek, getDayViewTotals } from "../../common/common.helpers"
import { PrimeSpeedRow, GraphDataPoint, DayViewTotals } from "../../common/common.types"

const useProjectedGraphData = (rollingdata: PrimeSpeedRow[]) => {
  const [filteredRollingData, setFilteredRollingData] = useState<PrimeSpeedRow[]>([])
  const [projectedGraphData, setProjectedGraphData] = useState<GraphDataPoint[]>([])
  const [projectedDayByDayData, setprojectedDayByDayData] = useState<DayViewTotals[]>([])
  const [projectedSummary, setProjectedSummary] = useState({
    oneDayPercent: 0,
    twoDayPercent: 0,
    greaterThanTwoDayPercent: 0,
    oneDayViews: 0,
    twoDayViews: 0,
    greaterThanTwoDayViews: 0,
    totalViews: 0
  })

  useEffect(() => {
    if (rollingdata) {
      setFilteredRollingData(getProjectedDataForWeek(rollingdata))
    }
  }, [rollingdata])

  useEffect(() => {
    if (filteredRollingData.length > 0) {
      setProjectedGraphData(calculateProjectedGraphData(filteredRollingData))
      setProjectedSummary(calculateProjectedSummary(filteredRollingData))
      setprojectedDayByDayData(getDayViewTotals(filteredRollingData))
    }
  }, [filteredRollingData])

  return { projectedGraphData, projectedSummary, filteredRollingData, projectedDayByDayData}
}

export default useProjectedGraphData