import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createColumnHelper,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Table as BTable, Button, Container, Form, FormControlProps, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { apiRequest } from "../utility/axios";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useSpinner } from "../spinner/SpinnerContext";
import { DateTime } from "luxon";
import { RankingInfo, rankItem, compareItems } from "@tanstack/match-sorter-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { notify } from "../notify/notify";

declare module "@tanstack/react-table" {
  //add fuzzy filter to the filterFns
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

type Tracking3PlRow = {
  name: string;
  shippingDetailsId: string;
  nhsOrderId: string;
  orderId3Pl: string;
  shipmentSentTime: string;
  status: string;
  markShipped?: string;
  expediteTracking?: boolean;
};

// Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const ShippingTracking = () => {
  const { toggleSpinner } = useSpinner();
  const [showModal, setShowModal] = useState(false);
  const columnHelper = createColumnHelper<Tracking3PlRow>();
  const [globalFilter, setGlobalFilter] = useState("");
  const [currentShippingDetailsId, setCurrentShippingDetailsId] = useState<string | null>(null);
  const [sorting, setSorting] = useState([
    { id: "shipment_sent_time", desc: true },
    { id: "status", desc: true },
  ]);

  const mutation = useMutation({
    mutationFn: async (body: { shippingDetailsId: string; expediteTracking: boolean }) => {
      const response = await apiRequest<any>("POST", `/api/shippingTracking`, body);
      return response;
    },
    onMutate: () => {
      toggleSpinner(true);
    },
    onSuccess: () => {
      setShowModal(false);
      setCurrentShippingDetailsId(null);
      refetch(); // Refetch the table data on success
    },
    onError: (error) => {
      notify("error", error.message);
      toggleSpinner(false);
    },
    onSettled: () => {
      toggleSpinner(false);
    },
  });

  const handleMarkShipped = (shippingDetailsId: string) => {
    setCurrentShippingDetailsId(shippingDetailsId);
    setShowModal(true);
  };

  const handleConfirm = async () => {
    if (currentShippingDetailsId) {
      const body = {
        shippingDetailsId: currentShippingDetailsId,
        expediteTracking: true,
      };
      mutation.mutate(body);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setCurrentShippingDetailsId(null);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        id: "name",
        cell: (info) => {
          return <div className="text-center">{info.getValue()}</div>;
        },
        header: () => <span>3PL</span>,
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.shippingDetailsId, {
        id: "shipping_details_id",
        cell: (info) => {
          return <div className="text-center">{info.getValue()}</div>;
        },
        header: () => <span>Shipping Details Id</span>,
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.nhsOrderId, {
        id: "nhs_order_id",
        cell: (info) => {
          return <div className="text-center">{info.getValue()}</div>;
        },
        header: () => <span>NHS Order Id</span>,
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.orderId3Pl, {
        id: "3pl_order_id",
        cell: (info) => {
          return <div className="text-center">{info.getValue()}</div>;
        },
        header: () => <span>3PL Order Id</span>,
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.shipmentSentTime, {
        id: "shipment_sent_time",
        cell: (info) => {
          let localTime = DateTime.fromISO(info.getValue()).toLocal().toFormat("yyyy-MM-dd HH:mm");
          return <div className="text-center">{localTime}</div>;
        },
        header: () => <span>Shipment Sent Time</span>,
      }),
      columnHelper.accessor((row) => row.status, {
        id: "status",
        cell: (info) => {
          return <div className="text-center">{info.getValue()}</div>;
        },
        header: () => <span>Status</span>,
      }),
      columnHelper.accessor((row) => row.shippingDetailsId, {
        id: "expedite_tracking",
        cell: (info) => {
          const shippingDetailsId = info.getValue();
          const rowData = info.row.original;
          const status = rowData.status;
          const expediteTracking = rowData.expediteTracking;
          const canMark = (status === "Not Found" || status === "Processing Tracking") && !expediteTracking;
          return (
            <>
              {canMark && (
                <div className="text-center">
                  <Button onClick={() => handleMarkShipped(shippingDetailsId)}>Expedite Tracking</Button>
                </div>
              )}
              {expediteTracking && (
                <div className="text-center">
                  <OverlayTrigger overlay={<Tooltip>Expedite Tracking Applied</Tooltip>}>
                    <FontAwesomeIcon icon={faCircleCheck} />
                  </OverlayTrigger>
                </div>
              )}
            </>
          );
        },
        header: () => <span>Expedite Tracking</span>,
        enableSorting: false,
      }),
    ],
    []
  );

  const { isLoading, isPending, error, data, isFetching, refetch } = useQuery<Tracking3PlRow[]>({
    queryKey: ["3plTrackingTable"],
    queryFn: async () => {
      const response = await apiRequest<Tracking3PlRow[]>("GET", `/api/shippingTracking`);
      return response;
    },
    // refetchInterval: 60000 * 2, // Auto refetch every 2 minutes
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    enableMultiSort: true,
    enableSorting: true,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: "fuzzy", //apply fuzzy filter to the global filter (most common use case for fuzzy filter)
    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },
    state: {
      globalFilter,
      sorting,
    },
    onSortingChange: setSorting,
  });

  useEffect(() => {
    toggleSpinner(isPending || isFetching || isLoading);
  }, [isPending, isFetching, isLoading]);

  if (error) return <div>{error.message}</div>;
  if (!data) return <div></div>;

  return (
    <>
      <Container fluid className="p-3 fs-4">
        <DebouncedInput
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilter(String(value))}
          className="fs-4 mb-3 shadow-sm p-3"
          placeholder="Search"
          type="text"
          aria-describedby="search"
        />
        <BTable striped bordered hover responsive size="sm">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    className={`text-center ${header.column.getCanSort() ? "cursor-pointer select-none" : ""}`}
                    key={header.id}
                    colSpan={header.colSpan}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: " 🔼",
                      desc: " 🔽",
                    }[header.column.getIsSorted() as string] ?? null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </BTable>
      </Container>

      <Modal show={showModal} onHide={handleCancel} centered={true} backdrop={"static"} style={{ fontSize: "16px" }}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to mark this as shipped?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

// A typical debounced input react component
// Define the props type
interface DebouncedInputProps extends Omit<FormControlProps, "onChange"> {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
}

const DebouncedInput: React.FC<DebouncedInputProps> = ({ value: initialValue, onChange, debounce = 500, ...props }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return <Form.Control {...props} value={value} onChange={handleChange} />;
};

export default ShippingTracking;
