import { DateTime } from "luxon";
import { Table } from "react-bootstrap";

const AmazonDayByDayProjected = ({ projectedDayByDayData }) => {  
  return(
    <div className="p-3 shadow-sm bg-light rounded">
      <p className="fs-2">Projected Day By Day</p>
      <Table>
        <thead>
          <tr>
            <th className={`text-center`}>
              Date
            </th>
            <th className={`text-center`}>
              1 Day
            </th>
            <th className={`text-center`}>
              2 Day
            </th>
            <th className={`text-center`}>
              More Than 2 Days
            </th>
          </tr>
        </thead>
        <tbody>
        {projectedDayByDayData.map((dayData) => (
            <tr key={dayData.date}>
              <td className={`text-center`}>{DateTime.fromISO(dayData.date).toFormat('MM/dd/yyyy')}</td>
              <td className={`text-center`}>{dayData.totalOneDayViews}</td>
              <td className={`text-center`}>{dayData.totalTwoDayViews}</td>
              <td className={`text-center`}>{dayData.totalGreaterThanTwoDayViews}</td>
            </tr>
        ))}
        </tbody>
      </Table>
    </div>
  )
}

export default AmazonDayByDayProjected;