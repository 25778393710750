import { DateTime } from "luxon";
import { DeliveryMetrics } from "../../common/common.types";
import * as XLSX from "xlsx"

const useAmazonDeliveryMetricsXlsxExport = (data: DeliveryMetrics[] | undefined) => {

  const exportXlsx = () => {
    if (!data) return

    try {

      const workbook = XLSX.utils.book_new()

      data.forEach((order, index) => {
        const worksheetData: (string | null)[][] = [];

        // Define order headers and data row
        const orderHeaders = [
          "Order ID", "Order date and time", "Promised ship date", "Promised delivery date without a promise extension",
          "Promised delivery date with a promise extension", "Actual ship date and time (carrier first scan)", "Actual delivery date and time",
          "Carrier name", "Ship method", "Tracking ID", "Unit count", "Actual zip", 
          "Origin zip", "Destination zip", "Eligibility metric: Delivered late without a promise extension?", 
          "Eligibility metric: Canceled by seller?", "Eligibility metric: Invalid tracking?", "Supporting metric: Delivered late with a promise extension?", 
          "Supporting metric: Shipped late (carrier first scan)?", "Order Day of Week", "DC", "Order Time", 
          "API Create Time"
        ];

        const orderData = [
          order.orderId,
          DateTime.fromISO(order.orderDateTime).toFormat('MM/dd/yyyy'),
          DateTime.fromISO(order.promisedShipDate).toFormat('MM/dd/yyyy'),
          DateTime.fromISO(order.promisedDeliveryDateNoExtension).toFormat('MM/dd/yyyy'),
          DateTime.fromISO(order.promisedDeliveryDateWithExtension).toFormat('MM/dd/yyyy'),
          order.actualShipDate ? DateTime.fromISO(order.actualShipDate).toFormat('MM/dd/yyyy hh:mm a') : 'NA',
          order.actualDeliveryDate ? DateTime.fromISO(order.actualDeliveryDate).toFormat('MM/dd/yyyy hh:mm a') : 'NA',
          order.carrierName,
          order.shipMethod === 'null' ? 'NA' : order.shipMethod,
          order.trackingId,
          order.unitCount,
          order.actualOrigin,
          order.givenOrigin,
          order.destinationZip,
          order.deliveredLateNoPromiseExtension ? 'Yes' : 'No',
          order.cancelledBySeller ? 'Yes' : 'No',
          order.invalidTracking ? 'Yes' : 'No',
          order.deliveredLateWithPromiseExtension ? 'Yes' : 'No',
          order.shippedLate ? 'Yes' : 'No',
          order.orderDayOfWeek,
          order.distributionCenter,
          DateTime.fromISO(order.orderCreatedDateTime).toFormat('MM/dd/yyyy hh:mm a'),
          DateTime.fromISO(order.apiOrderCreatedDateTime).toFormat('MM/dd/yyyy hh:mm a')
        ];

        // Add headers and data rows for the order
        worksheetData.push(orderHeaders);    // Header row
        worksheetData.push(orderData);       // Data row
        worksheetData.push([]);              // Blank row

        // Add 'Scans' section
        worksheetData.push(["Scans"]);       // Label row

        // Define scan headers and data
        const scanHeaders = ["Scan Date", "Event Description", "Scan City", "Scan State", "Scan Zip", "Scan Country", "Derived Status", "Exception Description", "Delay Status", "Delay Type", "Delay SubType"]; // Adjust to match scan event fields
        worksheetData.push(scanHeaders);     // Scan headers row

        // Loop through each scan event and add the data rows
        order.scanEvents.forEach(scan => {
          const scanRow = [
            DateTime.fromISO(scan.date).toFormat('MM/dd/yyyy hh:mm a'),
            scan.eventDescription,
            scan.scanLocation.city,
            scan.scanLocation.stateOrProvinceCode,
            scan.scanLocation.postalCode,
            scan.scanLocation.countryCode,
            scan.derivedStatus,
            scan.exceptionDescription,
            scan.delayDetail?.status,
            scan.delayDetail?.type,
            scan.delayDetail?.subType
          ];
          worksheetData.push(scanRow);
        });

        // Add determined and actual reasons at the end
        worksheetData.push([]);              // Blank row
        worksheetData.push(["Reason:"]);

        // Create a worksheet from the structured data
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

        // Append the worksheet to the workbook with a unique sheet name
        XLSX.utils.book_append_sheet(workbook, worksheet, `Order_${order.orderId}`);
      });

      // Export the workbook to an Excel file
      XLSX.writeFile(workbook, `FedExDeliveryMetricsExport_${DateTime.now().toFormat('MM-dd-yyyy')}.xlsx`);

    } catch (error) {
      console.error("Error exporting data to xlsx:", error);
    }
  };

  return { exportXlsx };
}

export default useAmazonDeliveryMetricsXlsxExport