import { Button, Row, Col, Container } from "react-bootstrap"

const AmazonDeliveryMetricsExportComponent = ({ exportFile }) => { 
  return(
    <Container>
      <Row className="justify-content-evenly">
        <Col>
          <Button variant="outline-primary" onClick={exportFile}>Export to Xlsx</Button>
        </Col>
      </Row>
    </Container>
  )
}

export default AmazonDeliveryMetricsExportComponent