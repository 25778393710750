import { Col, Container, Row } from "react-bootstrap";
import { AmazonDeliveryMetricsProvider, useAmazonDeliveryMetricsContext } from "./contexts/amazonDeliveryMetricsContext";
import AmazonDeliveryMetricsTableComponent from "./components/amazonDeliveryMetricsTable";
import AmazonDeliveryMetricsFilterComponent from "./components/amazonDeliveryMetricsFilter";
import UploadAmazonDeliveryMetricsComponent from "./components/amazonDeliveryMetricsUpload";
import AmazonDeliveryMetricsExportComponent from "./components/amazonDeliveryMetricsXlsxExport";

const AmazonDeliveryMetrics = () => {

  const {
    error,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    isLoadingCurrent,
    currentError,
    currentData,
    handleFileChange,
    uploadFile,
    handleApplyFilters,
    handleClearFilters,
    handleAmazonDeliveryMetricsExport
  } = useAmazonDeliveryMetricsContext()

  if (error) return <div>{error.message}</div>
  if (!currentData) return <div></div>

  return (
    <Container fluid className="mt-4 text-center">
      <Row className="justify-content-md-center mb-5">
        <Col md="auto" className="fs-2 fw-bold">Amazon Delivery Metrics</Col>
      </Row>
      <Row className="justify-content-md-center mb-3">
        <Col md="auto">
        <AmazonDeliveryMetricsFilterComponent 
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          handleApplyFilters={handleApplyFilters}
          handleClearFilters={handleClearFilters}
        />
        </Col>
        <Col md="auto">
          <UploadAmazonDeliveryMetricsComponent
            handleFileChange={handleFileChange}
            uploadFile={uploadFile}
          />
        </Col>
      </Row>
      <Row className="justify-content-md-center mb-3">
        <Col md="auto">
          <AmazonDeliveryMetricsExportComponent 
            exportFile={handleAmazonDeliveryMetricsExport}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <AmazonDeliveryMetricsTableComponent data={currentData} />
        </Col>
      </Row>
    </Container>
  )
}

export default function AmazonDeliveryMetricsWrapper() {
  return (
    <AmazonDeliveryMetricsProvider>
      <AmazonDeliveryMetrics />
    </AmazonDeliveryMetricsProvider>
  );
}